<script>
import NotifyService from '@/services/NotifyService'
import BreakingNewsNewView from './BreakingNewsNewView'

export default {
  name: 'BreakingNewsEditView',
  extends: BreakingNewsNewView,
  methods: {
    getBreakingNewsItem () {
      this.$store.dispatch('breakingnews/fetchOne', this.$route.params.id)
        .then(() => {
          this.breakingnews = this.$store.getters['breakingnews/detail']
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('breakingnews/update', this.breakingnews)
        .then(() => {
          if (this.$store.getters['breakingnews/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['breakingnews/error'])
          }
        })
        .catch(error => console.error(error))
    }
  },
  created () {
    this.isEditMode = true
    this.getBreakingNewsItem()
  }
}
</script>
